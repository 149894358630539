<div class="mainPage">
  <div class="privacyTitleDiv">平台服務及個人資料之蒐集、處理及利用告知暨同意書</div>

  <div class="privacyContentDiv">
    <div>
      一、平台服務使用條款及隱私權政策
      <ul>
        <li>
          1. 本服務使用條款及隱私權政策說明類神經網路股份有限公司（包括類神經網路股份有限公司及關係企業
          （下稱「本公司」））如何處理、保護使用者於使用本公司「諾和諾德HCP
          官方LINE」時所提供之資訊，以及使用者可以如何存取、控管資訊。為尊重使用者的隱私權、保護個人資訊，我們將遵循以下基本原則：
          <ul>
            <li>a. 蒐集使用者的個人資訊時，我們會依所執行業務之需求清楚說明目的。</li>
            <li>b. 個人資訊將被蒐集時，我們會事先通知使用者，以就個人資訊之蒐集、利用、揭露範圍徵詢使用者的同意。</li>
            <li>c. 我們僅會蒐集特定目的範圍內必要的個人資訊。</li>
            <li>
              d.
              除非經使用者的同意或基於法律要求，否則我們僅會針對蒐集目的利用或揭露使用者的個人資訊，且所蒐集之資訊僅會於蒐集目的完成及專案之所需期間內保留。
            </li>
            <li>e. 我們將以適當的安全保護措施保護使用者的個人資訊。</li>
            <li>f. 我們會盡力使處理個人資訊的政策與措施公開透明。</li>
            <li>g. 使用者可以存取個人資訊並進行適當的修改。</li>
            <li>h. 我們將會對使用者負責。使用者可以隨時針對我們是否遵循上述原則與此隱私權政策提出意見。</li>
            <li>
              i.
              除可能涉及違法、侵權、違反本使用條款、經本服務平台使用者同意或因政府行為以外，本服務不會將本服務平台使用者申請人之個人資料揭露或提供予第三人。
            </li>
          </ul>
        </li>
        <li>
          2. 使用者了解並同意本公司
          <ul>
            <li>
              a. 為向使用者提供最佳服務，並維護服務與使用安全，我們可能會使用 Cookies
              儲存使用者設定以記錄存取功能，並監控使用模式及數據。例如，我們可能會使用 Google Analytics
              協助我們進行上述工作。
            </li>
            <li>
              b.
              存取本服務時，可能會自動取得並儲存使用者的使用紀錄等存取記錄資訊。該資訊將用以分析使用者的環境，以便我們提升本服務，並避免未經授權／盜用行為干擾本服務正常運作。
            </li>
          </ul>
        </li>
        <li>
          3. 本公司係基於下列目的利用所蒐集之資訊：
          <ul>
            <li>a. 為使用者提供平穩流暢的本服務。</li>
            <li>b. 認證使用者的身分並協助專案相關申請內容。</li>
            <li>c. 避免未經授權使用／盜用或濫用本服務。</li>
            <li>d. 提昇或優化本服務。</li>
            <li>e. 為提供客戶支援，有效回應使用者的疑問與訴求。</li>
            <li>f. 累積本服務相關的匿名統計資料。</li>
            <li>g. 用於本服務相關的未來發展。</li>
            <li>h. 告知使用者其他服務相關重要資訊，於必要時與使用者聯絡；及遵守相關法律與法定義務。</li>
            <li>
              i.
              本公司會使用資料作以下用途：提供個人所有之資料授權合約程序，管理及交易等。並在個人之同意下，進行去個資化識別之分析，使個人得以獲取治療上之利益。
            </li>
          </ul>
        </li>
        <li>
          4.
          除本服務使用條款及隱私權政策及各專案相關授權（必要時亦包括其他相關隱私權政策與附錄）另有規定者外，未經使用者的同意，我們不會向專案內容外之單位提供使用者的資訊。但若是我們本於善意認為係依據相關法律之要求或許可，或為保護本公司權利及財產而進行抗辯，則不在此限。
        </li>
        <li>
          5.
          本公司可能不定期與服務供應商合作，協助我們為使用者提供最佳服務。在與其他服務供應商建立合作關係前，我們會確保服務供應商將依此隱私權政策與相關法律處理使用者的資訊。
          並依據其伺服器所在的國家，所蒐集之資料基於其伺服器分散位置，可能會進行國際傳輸。會進行目前合作之服務供應商包含：
          <ul>
            <li>a. LINE (台灣連線股份有限公司)</li>
            <li>b. Google (Google Cloud Platform）</li>
          </ul>
        </li>
        <li>
          6.
          在下列情況下，本服務有可能查看或提供本服務平台使用者的個人或相關電子訊息給相關政府機關或主張其權利受侵害並提出適當證明之第三人，必要時並得暫停本服務之全部或部分：
          <ul>
            <li>a. 依法令規定、依司法機關或其他政府機關的命令或要求；</li>
            <li>b. 為執行本使用條款或使用者有違反本使用條款或其他相關政策及規則；</li>
            <li>c. 為保護其他使用者或其他第三人的合法權益；</li>
            <li>d. 為保護本服務及其關聯企業之系統安全或合法權益；</li>
            <li>e. 為維護本服務本項服務系統之正常運作。</li>
          </ul>
        </li>
        <li>
          7.
          本公司致力協助使用者隨時確認或修改其註冊資訊。若無法直接於應用程式上進行確認，使用者可隨時書面要求本公司確認、更正或刪除其他本公司持有的個人資料。本公司將在認證使用者身分後，依相關法律合理期限內回應使用者的要求。
        </li>
        <li>
          8.
          我們將持續努力確保本服務的安全性。為執行上述工作，若本公司發現違反服務條款的情況或有可能發生違反情況，或涉及使用者之間的紛爭，我們的員工可能會存取使用者提供的資訊。
        </li>
        <li>
          9.
          隱私權政策修改相關說明：本公司可能不定期修改隱私權政策，以因應本服務及個人資料保護法及相關法律之變更。若隱私權政策有重大變更，我們將透過應用程式及/或其他方式通知使用者。使用者可隨時於系統查看我們的隱私權政策。如使用者繼續使用本服務，將視為同意本公司對隱私權政策之修改。
        </li>
        <li>
          10.
          因提供或使用本服務服務平台使用者的個人或相關電子訊息所涉之爭議，應適用中華民國法律，並以臺灣臺北地方法院為管轄法院。
        </li>
      </ul>
    </div>
    <div>
      二、台灣諾和諾德個人資料之蒐集、處理及利用告知暨同意書<br />
      台灣諾和諾德藥品股份有限公司（下稱「台灣諾和諾德」）為落實個人資料之保護，茲依據個人資料保護法（以下簡稱個資法）第八條規定告知下列事項：
      <ul>
        <li>
          1. 個人資料蒐集、處理及利用告知條款
          <ul>
            <li>
              a.
              台灣諾和諾德基於客，取得您的個人資料，且依據個資法及相關法令之規定，於合理關連之特定目的範圍內，蒐集、處理及利用姓名、
              性別、出生年份、身份證字號、手機號碼、電子信箱、Line
              ID帳號/暱稱/頭像、職業、互動歷程等個人資料，而為合法及合理方式利用。另外，為向使用者提供最佳服務，並維護服務與使用安全，台灣諾和諾德可能會使用
              本服務的記錄，包括但不限於地理定位、使用時間、cookies 儲存使用者設定以記錄存取功能、以及Google Analytics
              等數據工具進行上述工作。非經您的書面同意，或有其他合法依據，台灣諾和諾德不會將您的個人資料用於其他用途。
            </li>
            <li>
              b. 您同意台灣諾和諾德利用個人資料之期間、地區、對象及方式如下：
              <ul>
                <li>i. 期間：台灣諾和諾德存續期間或依法令之資料保存期間。</li>
                <li>ii. 地區：中華民國領域或其他為完成上開特定目的所必要之地區。</li>
                <li>iii. 對象：台灣諾和諾德、台灣諾和諾德之關係企業、諾和諾德委託第三方合作廠商。</li>
                <li>
                  iv.
                  方式：台灣諾和諾德基於執行業務及履行或服務，透過數位檔案或實體紙本形式蒐集您所留存的資料，並以自動化機器或其他非自動化方式之國際傳輸、書面或電子之利用方式(包含但不限於個人資料維護、整合等)，不定期傳送醫學會議資訊、科學研究報告資訊、產品相關訊息、
                  疾病領域資訊、疾病衛教訊息、問卷調查等。
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          2. 資訊安全及保護
          <ul>
            <li>
              a.
              台灣諾和諾德在執行業務所必須之範圍內，依個資法第二十七條規定採行個人資料保護法施行細則第十二條所規定之安全管理
              措施，以防止您的個人資料被竊取、竄改、 毀損、滅失或洩漏。
            </li>
            <li>
              b.
              台灣諾和諾德若有違反個資法規定，致您的個人資料被竊取、洩漏、竄改或其他侵害之情形時，於發現後，應立即通知您並採取因應措施，以避免或降低損害範圍。
            </li>
            <li>
              c. 您若因台灣諾和諾德違反個資法規定致受有損害，台灣諾和諾德除能證明其無故意或過失外，應負損害賠償責任。
            </li>
          </ul>
        </li>
        <li>
          3. 不提供個人資料相關權益之影響
          <ul>
            <li>
              a.
              您得自由選擇是否提供個人資料，並知悉若不提供，台灣諾和諾德將無法提供上開特定目的相關業務之執行及履行或服務。
              經台灣諾和諾德藥品股份有限公司向本人告知上開事項，本人已清楚瞭解上開告知內容，並同意台灣諾和諾德得藥品股份有限公司於上開特定目的範圍內蒐集、處理、利用
              本人個人資料及上開相關事項。本人並同意以電子文件作為行使個資法書面同意之方式，並瞭解此一同意符合個人資料保護法及相關法規之要求，具有書面同意基於上述目的蒐集、處理及利用您的個人資料之效果。
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="privacyBtnDiv">
    <button mat-button mat-dialog-close class="privacyBtn">確認</button>
  </div>
</div>
